import React from 'react';
import { cx } from 'src/shared/utils/common';
import { Film } from 'react-feather';
import { Link } from 'react-router-dom';
import { useAppRoutesContext } from 'src/context/routes';
import { TSVideo } from 'src/entities/videos/domain';
import { SpinnerAnimation } from 'src/shared/ui/assets/SpinnerAnimation';
import css from './VideoThumbnailLink.module.scss';

interface VideoThumbnailLinkProps {
  className?: string;
  video: TSVideo;
  style?: React.CSSProperties;
  isSmall?: boolean;
}

export const VideoThumbnailLink: React.FC<VideoThumbnailLinkProps> = ({
  children,
  className,
  video,
  style,
  isSmall,
}) => {
  const routes = useAppRoutesContext();

  return (
    <Link
      className={cx('d-block position-relative', !isSmall && 'w-100', css.thumbnailBox, className)}
      to={routes.videos.videoEditor(video.id)}
      style={{ backgroundImage: `url('${video.thumbnailUrl}')`, ...style }}
    >
      {!video.thumbnailUrl && <SpinnerAnimation className={css.loader} />}
      <div
        className={cx(
          'position-absolute top-0 start-0 h-100 w-100 d-flex align-items-center justify-content-center',
          css.thumbnailOverlay,
        )}
      >
        <Film className={isSmall ? css.filmIconSmall : css.filmIcon} />
        {children}
      </div>
    </Link>
  );
};
