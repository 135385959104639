export interface LYGCompetitionLeaderboardTeam {
  id: number;
  poolName: string;
  poolId: number;
  teamName: string;
  teamId: number;
  points: number;
  wins: number;
  losses: number;
  draws: number;
  pointsDifference: number;
  gamesPlayed: number;
  totalScorePoints: number | null;
}

type LYGCompetitionLeaderboardRecordProps = OnlyData<LYGCompetitionLeaderboardTeam>;

export class LYGCompetitionLeaderboardTeam {
  constructor(init: LYGCompetitionLeaderboardRecordProps) {
    Object.assign(this, init);
  }
}

export type LYGCompetitionLeaderboard = { poolId: number; poolName: string; teams: LYGCompetitionLeaderboardTeam[] }[];
