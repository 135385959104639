import React from 'react';
import { useI18nContext } from 'src/context/i18n';
import { useParams, useNavigate } from 'react-router-dom';
import { Card } from 'src/shared/ui/widgets/Card';
import { CardTitle } from 'src/shared/ui/widgets/Card';
import { AuthenticatedPage } from 'src/shared/ui/layout/AuthenticatedPage';
import { useTeamQuery } from 'src/entities/teams/queries/teamQuery';
import { Observer } from 'mobx-react-lite';
import { FormFieldWrapper } from 'src/shared/ui/inputs/FormFieldWrapper';
import { TextField } from 'src/shared/ui/inputs/text/TextField';
import { PrimaryButton, CancelButton, PrimaryInvertedButton } from 'src/shared/ui/buttons';
import { useAppRoutesContext } from 'src/context/routes';
import { CreateTeamPlayerModel, PlayerFormFields } from 'src/pages/teams/createTeamPlayer/model';
import { ToggleField } from 'src/shared/ui/inputs/boolean/ToggleField';
import { MinusCircle } from 'react-feather';
import { useAddTeamPlayersMutation } from './mutations';

export const CreateTeamPlayerPage: React.FC = () => {
  const i18n = useI18nContext();
  const { teamId } = useParams();
  const navigate = useNavigate();
  const routes = useAppRoutesContext();
  const teamQuery = useTeamQuery(Number(teamId));
  const [model] = React.useState(new CreateTeamPlayerModel({ i18n }));

  const addPlayersMutation = useAddTeamPlayersMutation(model, Number(teamId), () => {
    navigate(routes.teams.team(Number(teamId)));
  });

  return (
    <AuthenticatedPage query={teamQuery} title={teamQuery.data?.name || i18n.t('pages.teams.teamPage.management')}>
      {() => (
        <div className='mx-auto ts-form-width'>
          <Card>
            <CardTitle className='mb-4'>Add New Player</CardTitle>

            <Observer>
              {() => (
                <>
                  {model.players.map((player: PlayerFormFields, index: number) => (
                    <div key={index} className='mb-0'>
                      {/* {index > 0 && <div className='mb-4 border-top' />} */}
                      <div className='d-flex flex-column flex-md-row gap-1 gap-md-4 mb-1 position-relative'>
                        <FormFieldWrapper className='w-100' model={player.firstName}>
                          <TextField model={player.firstName} />
                        </FormFieldWrapper>
                        <FormFieldWrapper className='w-100' model={player.lastName}>
                          <TextField model={player.lastName} />
                        </FormFieldWrapper>
                        <FormFieldWrapper className='w-100' model={player.email}>
                          <TextField model={player.email} />
                        </FormFieldWrapper>
                        {model.players.length > 1 && index > 0 && (
                          <PrimaryInvertedButton
                            onClick={() => model.removePlayer(index)}
                            className='align-self-end mb-3 position-absolute end-0'
                            style={{ top: '-8px' }}
                          >
                            <MinusCircle />
                          </PrimaryInvertedButton>
                        )}
                      </div>
                    </div>
                  ))}

                  <PrimaryInvertedButton onClick={() => model.addPlayer()}>Add another player</PrimaryInvertedButton>

                  <div className='d-flex align-items-center mt-3'>
                    <ToggleField model={model.notifyPlayersField} />
                  </div>
                </>
              )}
            </Observer>
          </Card>

          <Observer>
            {() => (
              <PrimaryButton
                className='w-100 mt-4 ts-form-button'
                onClick={() => addPlayersMutation.mutate()}
                disabled={addPlayersMutation.isLoading || model.hasError}
              >
                Create Accounts
              </PrimaryButton>
            )}
          </Observer>

          <CancelButton
            className='w-100 my-3 ts-form-button'
            onClick={() => navigate(routes.teams.team(Number(teamId)))}
          >
            {i18n.t('shared.actions.cancel')}
          </CancelButton>
        </div>
      )}
    </AuthenticatedPage>
  );
};
