import { useQuery, UseQueryResult } from 'react-query';
import { useAuthContext } from 'src/context/authContext';
import { getTeam, GetTeamResponseItem } from 'src/entities/teams/apis/getTeam';
import { TeamSimple } from 'src/entities/teams/domain';
import { TEAM_QUERY_KEYS } from 'src/entities/teams/queries/queryKeys';

function mapCoachData(coach: GetTeamResponseItem['head_coach']) {
  return {
    id: coach[0]?.profile_id,
    jerseyNumber: coach[0]?.profile_number,
    name: `${coach[0]?.profile_first_name} ${coach[0]?.profile_last_name}`,
    position: coach[0]?.profile_position,
    registrationNumber: coach[0]?.profile_reg_number,
    registrationFileUrl: coach[0]?.profile_reg_file,
    firstName: coach[0]?.profile_first_name,
    lastName: coach[0]?.profile_last_name,
    avatarUrl: coach[0]?.profile_avatar,
    phone: coach[0]?.profile_telephone1,
    email: coach[0]?.email,
  };
}

export function useTeamQuery(teamId: number): UseQueryResult<TeamSimple | null, Error> {
  const { token } = useAuthContext();

  return useQuery<TeamSimple | null, Error>(
    TEAM_QUERY_KEYS.team(teamId),
    async () => {
      const [response] = await getTeam(teamId, token);

      if (!response) {
        return null;
      }

      return {
        code: response.team_code,
        description: response.team_description || undefined,
        id: response.team_id,
        logoUrl: response.team_avatar || undefined,
        name: response.team_name,
        playerCount: response.player_count || 0,
        tags: JSON.parse(response.team_tags || '[]'),
        club: { id: response.org_id, name: response.org_name },
        venues: response.team_venues.map(venue => {
          return {
            id: venue.venue_id,
            address: venue.venue_address1 || '',
            fullAddress: venue.venue_full_address || '',
            city: venue.venue_city || '',
            postcode: venue.venue_postcode || '',
            name: venue.venue_name || '',
          };
        }),
        headCoach: mapCoachData(response.head_coach),
        coach1: response.coach1,
        coach2: response.coach2,
        coach3: response.coach3,
        coach1Role: response.team_coach1_role,
        coach2Role: response.team_coach2_role,
        coach3Role: response.team_coach3_role,
        coaches: response?.coaches?.map(coach => mapCoachData(coach)) || [],
        division: response.team_division,
        beLeague: response.team_uk_basketball_be_league || undefined,
        beCompetition: response.team_uk_basketball_be_div_stage || undefined,
      };
    },
    {
      staleTime: 1000 * 60 * 15,
      enabled: !isNaN(teamId),
    },
  );
}
